<template>
  <!-- 菜单 -->
  <div class="menu">
    <toolbar></toolbar>
    <div class="tabbar">

      <div v-for="(item, index) in menuList" :key="item.id" class="Onemenu">
        <div
          class="nextMenu"
          :class="{ checkSty: checkInd == index }"
          id="next"
        >
          <div
            v-for="(item, index) in childMenuList.subMenuList"
            :key="index"
            @click="brandIntroduction(item.menuId)"
          >
            {{ item.menuName }}
          </div>
        </div>
        <div @click="showChild(item, index)" class="oneName">
          {{ item.menuName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { homemenu, menuid } from "@/api/path";
import toolbar from '@/components/toolbar.vue'

export default {
  data() {
    return {
      menuList: [],
      checkInd: -1,
      childMenuList: [],
      isShow: false,
    };
  },
  components:{
    toolbar,
  },
  created() {
    this.getAllMenu();
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
  },
  methods: {
    showChild(item, index) {
      this.isShow = false;
      this.checkInd = index;
      menuid(item.menuId)
        .then((res) => {
          this.childMenuList = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onSelect(action) {
      Toast(action.text);
    },
    // 子品牌介绍
    brandIntroduction(mid) {
      let query = {
        mid: mid,
      };
      switch (mid) {
        case 9: // 应用服务 /levelThreePage?menuId=36
          this.$router.push({
            path: "/levelThreePage",
            query: { menuId: 36 },
          });
           break;
        case 10: // 资料中心
        case 11: // 文献指南
        case 12: // 在线课堂
        case 13: // 学术分享
          this.$router.push({ path: "/secondaryPages", query });
          break;
        case 7: // 应用领域
        case 8: // 产品介绍
          this.$router.push({ path: "/secondaryPagesYYLY", query });
          break;
        case 4: // 品牌介绍
          this.$router.push({ path: "/secondaryPagesPPJS", query });
          break;
        case 5: // 活动信息
          this.$router.push({ path: "/secondaryPagesHDXX", query });
          break;
        case 6: // 联系我们
          this.$router.push({ path: "/secondaryPagesLXWM", query });
          break;
        default:
          console.log("NOT FOUNT");
      }
    },
    getAllMenu() {
      homemenu().then((res) => {
        this.menuList = res.data;
      });
    },
    // 返回事件处理
    backButton() {
      history.pushState(null, null, document.URL);
    },
  },
  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>

<style lang="scss" scoped>
.menu {
  background-color: #f1f1f1;
  height: 100vh;
  font-size: 4vw;
  display: flex;
  align-items: flex-end;
}
.tabbar {
  position: absolute;
  right: 0;
  display: flex;
  align-items: flex-end;
  .Onemenu {
    width: 100%;
    text-align: center;
    .oneName {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 15px 50px;
      background-color: #ffffff;
      border-top: 1px solid #111111;
      border-left: 1px solid #111111;
    }
    .nextMenu {
      background-color: #ffffff;
      display: none;
      div {
        border: 1px solid #111111;
        border-bottom: 0px;
        padding: 10px 0;
      }
    }
  }
}
.checkSty {
  display: block !important ;
}
</style>
