<template>
    <div class="positioning">
        
    </div>
</template>
<script>
export default{

}
</script>
  
<style lang="scss" scoped>
.positioning{
    position: absolute;
    top: 0px;
    left: 0px;
}
</style>